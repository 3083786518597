import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from 'store/axiosBaseQuery';
import {
    AIResponse,
    CandidateAnalysis,
    CandidateAnalysisSkill,
    CVClassification,
    CVSummary,
    RecruitmentQuestion,
    RecruitmentQuestions,
    RecruitmentTranscription
} from 'models/ai';
import { Environment } from 'environment';
import _ from 'lodash';

// const local = false;
// const baseUrlOverride = local ? 'https://localhost:8003/api/v1/ai' : undefined;

const baseUrlOverrideV2 = Environment.apiUrl.replace('v1', 'v2');
const baseUrlOverrideV3 = Environment.apiUrl.replace('v1', 'v3');

export const aiApiService = createApi({
    reducerPath: 'aiApiService',
    baseQuery: axiosBaseQuery({ basePath: '/ai' }),
    endpoints: (builder) => ({
        summarizeCV: builder.mutation<
            CVSummary,
            { position: string; level: string; skills: string[]; question?: string; file: File }
        >({
            query: ({ position, level, skills, question, file }) => {
                const formData = new FormData();
                formData.append('file', file);

                return {
                    baseUrlOverride: baseUrlOverrideV2,
                    // url: `/cv/summary/${position}/${level}`,
                    url: `/cloude/cv/summary/${position}/${level}`,
                    params: {
                        question,
                        skills: skills.join(',')
                    },
                    method: 'post',
                    data: formData
                };
            }
        }),
        classifyCV: builder.mutation<CVClassification, { position: string; level: string; skills: string[]; cv: File }>({
            query: ({ position, level, skills, cv }) => {
                const formData = new FormData();
                formData.append('file', cv);

                return {
                    // baseUrlOverride,
                    url: `/cv/summary/classification/${position}/${level}`,
                    method: 'post',
                    params: {
                        list_of_skills: skills.join(',')
                    },
                    data: formData
                };
            }
        }),
        generateQuestions: builder.query<
            RecruitmentQuestion[],
            { position: string; level: string; skills: string[]; quantity: number }
        >({
            query: ({ position, level, skills, quantity }) => ({
                // baseUrlOverride,
                url: `/questions/generate/${position}/${level}`,
                method: 'get',
                params: {
                    quantity,
                    skills: skills.join(',')
                }
            }),
            transformResponse: (response: RecruitmentQuestions) => {
                return response.qa.map((q) => ({
                    question: q.question,
                    answer: q.answers ? q.answers.join(' ') : q.answer
                }));
            }
        }),
        generateExercise: builder.query<string, { position: string; level: string; skills: string[] }>({
            query: ({ position, level, skills }) => ({
                // baseUrlOverride,
                url: `/exercises/generate/${position}/${level}`,
                method: 'get',
                params: {
                    skills: skills.join(',')
                }
            }),
            transformResponse: (response: AIResponse) => {
                return response.content;
            }
        }),
        generateSpreadsheetExercises: builder.query<string, { level: string; quantity: number }>({
            query: ({ level, quantity }) => ({
                baseUrlOverride: baseUrlOverrideV2,
                url: `/wb_exercises/generate/${level}`,
                method: 'get',
                params: {
                    q_number: quantity
                }
            }),
            transformResponse: (response: AIResponse) => {
                return response.content;
            }
        }),
        transcribeInterview: builder.mutation<
            RecruitmentTranscription,
            { position: string; level: string; skills: string[]; recording: File; questions: File }
        >({
            query: ({ position, level, skills, recording, questions }) => {
                const formData = new FormData();
                formData.append('file', recording);
                formData.append('questions', questions);

                return {
                    // baseUrlOverride,
                    url: `/transcript/summary/${position}/${level}`,
                    method: 'post',
                    params: {
                        list_of_skills: skills.join(',')
                    },
                    data: formData
                };
            },
            transformResponse: (response: RecruitmentTranscription) => {
                return {
                    ...response,
                    classification_skills: response.classification_skills
                        .replaceAll('Years of experience', 'estimated years of experience')
                        .replaceAll('years_of_experience', 'estimated years of experience')
                };
            }
        }),
        comparativeAnalysis: builder.mutation<
            CandidateAnalysis[],
            { position: string; level: string; skills: string[]; cvs: File[] }
        >({
            query: ({ position, level, skills, cvs }) => {
                const formData = new FormData();
                cvs.forEach((cv) => {
                    formData.append('files', cv);
                });

                return {
                    baseUrlOverride: baseUrlOverrideV3,
                    url: `/analysis/many`,
                    method: 'post',
                    params: {
                        position,
                        level,
                        skills: skills.join(',')
                    },
                    data: formData
                };
            },
            transformResponse: ({ csv }) => {
                const rows: string[] = csv.split('\n');

                const usersWithSkills = rows
                    .filter((row) => row)
                    .map((row) => row.split(';'))
                    .map((row) => ({
                        name: row[0],
                        skill: row[1],
                        level: row[2],
                        score: row[3],
                        comment: row[4]
                    }));

                const groupedUsersWithSkills = _.groupBy(usersWithSkills, 'name');

                const analyses: CandidateAnalysis[] = _.keys(groupedUsersWithSkills).map((name) => {
                    const skills: CandidateAnalysisSkill[] = groupedUsersWithSkills[name].map((skill) => {
                        const score = parseInt(_.trim(skill.score, '%'));

                        return {
                            name: skill.skill,
                            level: skill.level,
                            score: _.isNaN(score) ? 0 : score,
                            comment: skill.comment
                        };
                    });

                    const analysis: CandidateAnalysis = {
                        name,
                        score: _.meanBy(skills, 'score'),
                        skills
                    };

                    return analysis;
                });

                return _.orderBy(analyses, ['score'], ['desc']);
            }
        })
    })
});

export const {
    useSummarizeCVMutation,
    useLazyGenerateQuestionsQuery,
    useLazyGenerateExerciseQuery,
    useLazyGenerateSpreadsheetExercisesQuery,
    useTranscribeInterviewMutation,
    useClassifyCVMutation,
    useComparativeAnalysisMutation
} = aiApiService;
